import { META_TAGS } from '@/configs/seo';
import { NextSeo } from 'next-seo';

export interface MetaTagsProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  canonical?: string;
  width?: number;
  height?: number;
}

const MetaTags = ({
  title = META_TAGS.title,
  description = META_TAGS.description,
  image = META_TAGS.image,
  url,
  canonical,
  width,
  height,
}: MetaTagsProps) => {
  return (
    <NextSeo
      title={title}
      description={description}
      canonical={canonical || url || 'https://link3.to'}
      openGraph={{
        url: url,
        title: title,
        description: description,
        images: [
          {
            url: image,
            alt: 'A Web3 Social Network of Verifiable Identities',
            type: 'image/png',
            width: width || 1280,
            height: height || 640,
          },
        ],
        site_name: 'Link3.to',
        type: 'profile',
      }}
      twitter={{
        handle: '@link3to',
        site: `https://link3.to`,
        cardType: 'summary_large_image',
      }}
    />
  );
};

export default MetaTags;
