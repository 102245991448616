import { revalidateSSGPage } from '@/utils';
import { getProfileQueryParams } from '@cyber-co/utils';
import {
  CreateBlockMutationVariables,
  CreateBlockResponse_Status,
  api,
  useCreateBlockMutation,
} from '@cyber/service/pwa';
import { toast } from '@cyberlab/uikit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export type TCreateBlockParams = CreateBlockMutationVariables & { handle: string };

export const useCreateBlocks: () => [
  updater: (p: TCreateBlockParams) => Promise<{ isSuccess: boolean }>,
  result: any,
] = () => {
  const dispatch: any = useDispatch();

  const [updater, result] = useCreateBlockMutation();

  const handleUpdate = useCallback(
    async ({ profileId, input, handle }: TCreateBlockParams) => {
      const res = await updater({ profileId, input }).then((data) => {
        if ('data' in data && data.data.createBlock.status === CreateBlockResponse_Status.Success) {
          return { isSuccess: true };
        }

        return { isSuccess: false };
      });

      if (res.isSuccess) {
        dispatch(api.endpoints.getProfile.initiate(getProfileQueryParams(handle), { forceRefetch: true }));

        toast.success('Update Successfully!');
        revalidateSSGPage(handle);
      }

      return res;
    },
    [updater],
  );

  return [handleUpdate, result];
};
