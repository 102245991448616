import { PropsWithChildren, createContext, useContext } from 'react';

type TProfileConfig = { isPreview?: boolean };

const context = createContext<TProfileConfig>({ isPreview: false });

const ProfileContextProvider = (props: PropsWithChildren<TProfileConfig>) => (
  <context.Provider value={{ isPreview: props.isPreview }}>{props.children}</context.Provider>
);

export const useProfileConfig = () => {
  const { isPreview } = useContext(context);

  return { isPreview };
};

export default ProfileContextProvider;
