import { useIsIOS } from '@/hooks';
import { Stack } from '@cyberlab/uikit';
import { PropsWithChildren } from 'react';
import MetaTags, { MetaTagsProps } from '../MetaTags';

export default function PWALayout(props: PropsWithChildren<{ metadata?: MetaTagsProps }>) {
  const isIOS = useIsIOS();
  const footerHeight = isIOS ? 44 : 0;
  return (
    <Stack
      sx={{
        paddingBottom: `${footerHeight}px`,
        // height: `calc(100vh - ${footerHeight}px)`,
      }}
      className='pwa-layout'
    >
      <MetaTags {...props.metadata} />
      {props.children}
    </Stack>
  );
}
