import { OrgProfileFragment, PersonalProfileFragment, Profile, ProfileFragment } from '@cyber/service/pwa';
import { ModalProps } from '@cyberlab/uikit/src/Modal';

type PersonalProfile = Pick<Profile, 'id' | 'handle' | 'type' | 'blocks'> & PersonalProfileFragment;

type OrgProfile = Pick<Profile, 'id' | 'handle' | 'type' | 'blocks'> & OrgProfileFragment;

export interface IPersonalBasicInfoProps {
  data?: Partial<PersonalProfile>;
}

export interface IOrgBasicInfoProps {
  data?: Partial<OrgProfile>;
}

export interface IBlockProps {
  profileId: string;
  isCreate?: boolean;
}

export interface TEditModalProps extends Pick<ModalProps, 'open' | 'onClose'> {
  profileId: string;
}

export const isPersonalProfile = (p?: ProfileFragment | null): p is PersonalProfile => p?.__typename === 'PerProfile';

export const isOrgProfile = (p?: ProfileFragment | null): p is OrgProfile => p?.__typename === 'OrgProfile';
