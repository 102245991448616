//imports needed for this function
// import fs from 'fs';
import { api } from '@cyber/service/pwa';
import { store } from '../store';

export const pinFileToIPFS = async (file: any) => {
  try {
    // @ts-ignore
    const res = await store.dispatch(api.endpoints.pinFile.initiate({ file }, undefined));

    return { ipfshash: res.data.pinFileToIPFS.cid };
  } catch (error) {
    return { ipfshash: '' };
  }
};

export const pinJSONToIPFS = async (json: { [key: string]: any }) => {
  try {
    // @ts-ignore
    const res = await store.dispatch(api.endpoints.pinJson.initiate({ json: JSON.stringify(json) }, undefined));

    return res.data.pinJsonToIPFS.cid;
  } catch (error) {
    return '';
  }
};
