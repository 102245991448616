import { pinFileToIPFS } from '@/services/pinFileToIpfs';
import { revalidateSSGPage } from '@/utils';
import { IsBlob, fixNFTIPFSURL, getProfileQueryParams } from '@cyber-co/utils';
import {
  PersonalPicture_Type,
  UpdatePersonalMutationVariables,
  UpdateProfileResponse_Status,
  Upload_Status,
  api,
  useUpdatePersonalMutation,
  useUploadProfileBackgroundMutation,
} from '@cyber/service/pwa';
import { toast } from '@cyberlab/uikit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const errorMsg: any = {
  [Upload_Status.NotLogin]: 'Please login frist',
  [Upload_Status.PermissionDenied]: 'You do not have access to create or update an event. Please contact your admin.', // 非当前 org 有创建 event 权限的用户操作
  [Upload_Status.SizeExceeded]: 'Failed to upload image. The maximum size for uploaded images is 1MB.', // max 1MB, custom user file uploader 处拦截， 此处无需处理, twitter user avatar 触发
  [Upload_Status.TypeUnsupported]: 'Only JPG, PNG, and WEBP file types are supported.', // 文件类型只支持jpg, png, webp 三种, 已在file uploader处拦截，此处无需处理
};

export const useEnhancedUpdatePersonal: () => [
  updater: ({ id, input }: UpdatePersonalMutationVariables & { handle: string }) => Promise<{ isSuccess: boolean }>,
  result: any,
] = () => {
  const dispatch: any = useDispatch();

  const [updater, result] = useUpdatePersonalMutation();
  const [uploadBackground] = useUploadProfileBackgroundMutation();

  const handleUpdate = useCallback(
    async ({ id, input, handle }: UpdatePersonalMutationVariables & { handle: string }) => {
      const { profilePicture, backgroundPicture } = input;

      // upload avatar start
      let userUploadAvatarRes: any;
      if (IsBlob(profilePicture?.picture)) {
        userUploadAvatarRes = await pinFileToIPFS(profilePicture?.picture);

        if (!userUploadAvatarRes?.ipfshash) {
          toast.error('Upload avatar failed, please retry.');

          return { isSuccess: false };
        }
      }
      const finalAvatar = fixNFTIPFSURL(userUploadAvatarRes?.ipfshash || profilePicture?.picture);
      // upload avatar end

      //upload background start
      let bgPictureRes;

      if (IsBlob(backgroundPicture?.picture)) {
        bgPictureRes = await uploadBackground({ file: backgroundPicture?.picture, profileId: id }).then((data) => {
          if ('data' in data) {
            return {
              isSuccess: data.data.uploadProfileImage.status === Upload_Status.Success,
              url: data.data.uploadProfileImage.url,
              status: data.data.uploadProfileImage.status,
            };
          }

          return {
            isSuccess: false,
            url: '',
          };
        });

        if (!bgPictureRes?.isSuccess) {
          toast.error(
            bgPictureRes.status ? errorMsg[bgPictureRes.status] : 'Upload background picture failed, please retry.',
          );

          return { isSuccess: false };
        }
      }
      const newBackground = bgPictureRes?.url || backgroundPicture?.picture || '';
      //upload background end

      const res = await updater({
        id,
        input: {
          ...input,
          profilePicture: {
            type: PersonalPicture_Type.General,
            picture: finalAvatar,
          },
          backgroundPicture: {
            type: PersonalPicture_Type.General,
            picture: newBackground,
          },
        },
      }).then((data) => {
        if ('data' in data && data.data.updatePersonal.status === UpdateProfileResponse_Status.Success) {
          return { isSuccess: true };
        }
        return { isSuccess: false };
      });

      if (res.isSuccess) {
        dispatch(api.endpoints.getProfile.initiate(getProfileQueryParams(handle), { forceRefetch: true }));
        dispatch(api.endpoints.EasAttestation.initiate(getProfileQueryParams(id), { forceRefetch: true }));
        dispatch(api.endpoints.getProfileIdentities.initiate(getProfileQueryParams(id), { forceRefetch: true }));
        dispatch(api.endpoints.me.initiate(undefined, { forceRefetch: true }));

        toast.success('Update Successfully!');
        revalidateSSGPage(handle);
      }

      return res;
    },
    [updater],
  );

  return [handleUpdate, result];
};
