import { revalidateSSGPage } from '@/utils';
import { getProfileQueryParams } from '@cyber-co/utils';
import {
  UpdateBlockMutationVariables,
  UpdateBlockResponse_Status,
  api,
  useUpdateBlockMutation,
} from '@cyber/service/pwa';
import { toast } from '@cyberlab/uikit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export type TUpdateBlockParams = UpdateBlockMutationVariables & { handle: string };

export const useUpdateBlocks: () => [
  updater: (p: TUpdateBlockParams) => Promise<{ isSuccess: boolean }>,
  result: any,
] = () => {
  const [updater, result] = useUpdateBlockMutation();

  const dispatch: any = useDispatch();

  const handleUpdate = useCallback(
    async ({ id, input, handle }: TUpdateBlockParams) => {
      const res = await updater({ id, input }).then((data) => {
        if ('data' in data && data.data.updateBlock.status === UpdateBlockResponse_Status.Success) {
          return { isSuccess: true };
        }

        return { isSuccess: false };
      });

      if (res.isSuccess) {
        dispatch(api.endpoints.getProfile.initiate(getProfileQueryParams(handle), { forceRefetch: true }));
        toast.success('Update Successfully!');
        revalidateSSGPage(handle);
      }

      return res;
    },
    [updater],
  );

  return [handleUpdate, result];
};
