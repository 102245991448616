import { revalidateSSGPage } from '@/utils';
import { getProfileQueryParams } from '@cyber-co/utils';
import {
  DeleteBlockMutationVariables,
  DeleteBlockResponse_Status,
  api,
  useDeleteBlockMutation,
} from '@cyber/service/pwa';
import { toast } from '@cyberlab/uikit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useDeleteBlocks: () => [
  updater: ({ id }: DeleteBlockMutationVariables & { handle: string }) => Promise<{ isSuccess: boolean }>,
  result: any,
] = () => {
  const dispatch: any = useDispatch();

  const [deleteTrigger, result] = useDeleteBlockMutation();
  const handleDelete = useCallback(
    async ({ id, handle }: DeleteBlockMutationVariables & { handle: string }) => {
      const res = await deleteTrigger({ id }).then((data) => {
        if ('data' in data && data.data.deleteBlock.status === DeleteBlockResponse_Status.Success) {
          return { isSuccess: true };
        }
        return { isSuccess: false };
      });

      if (res.isSuccess) {
        dispatch(api.endpoints.getProfile.initiate(getProfileQueryParams(handle), { forceRefetch: true }));

        toast.success('Delete Successfully!');
        revalidateSSGPage(handle);
      }

      return res;
    },
    [deleteTrigger],
  );

  return [handleDelete, result];
};
